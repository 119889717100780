.full-width-card {
    width: 100%;
}


.project-card {
    height: 100%;

    img {
        border-top: 10px solid $color-black;
        border-bottom: 10px solid $color-black;
    }

    .card-divider {
        background-color: $purple-120;
        color: $color-white;
        min-height: 80px;
    }

    .card-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}