$nav-breakpoint: 768px;

.header-wrapper,
.footer-wrapper {
    margin: auto;
    width: 100%;
}

.header-wrapper {
    margin-top: 15px;
    position: sticky;
    height: 30px;
}

header {
    background: rgb(206, 213, 244);
    background: linear-gradient(36deg, rgba(206, 213, 244, 1) 0%, rgba(95, 9, 121, 1) 79%, rgba(23, 86, 110, 1) 100%);
    width: 100%;
    /* animation magic */
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    z-index: 9999;
    position: fixed;
    top: 0;
    
    &-items {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 60%;
    }

    .role-nav {
        a {
            text-decoration: none;
            position: relative;

            &::after {
                content: '';
                background-color: $color-white;
                position: absolute;
                left: 0;
                bottom: -2px;
                height: 1px;
                width: 100%;
                transition: all 0.3s ease;
            }
        
            &:hover,
            &:focus {
                &::after {
                    width: 0;
                }
            }
        }
    }
}

.brand {
    width: 20%;
    float: left;
}

.logo-1 {
    font-size: 0;
    height: 100px;
    margin: 10px 0;
    position: relative;
    top: 5px;
    transition: all 0.3s ease-in;
    width: 180px;
}

.logo-1-background {
    background-image: url('../images/moth-logo-large.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    transition: all 0.3s ease-in;
}

.logo-2 {
    background-image: url('../images/moth-logo-horizontal-dark.svg');
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0;
    top: 10px;
    height: 100%;
    position: absolute;
    transform: translateZ(0) scale(1, 1) !important;
    -webkit-transform: translate3d(0, 0, 0);
    width: 200px;
    animation: fadeIn ease 0.3s;
    -webkit-animation: fadeIn ease 0.3s;
    -moz-animation: fadeIn ease 0.3s;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}

/* Mobile logo */
.logo-3 {
    background-image: url('../images/moth-logo-horizontal-light.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    font-size: 0;
    padding-bottom: 20px;
    height: 30px;
    width: 150px;
}

.logo {
    min-height: 26px;
    line-height: 0;
}

header {
    display: none;

    @media (min-width: $nav-breakpoint) {
        display: block;
    }
}

.mobile-header {
    max-height: 100vh;
    overflow-y: scroll;
    background: rgb(119, 48, 21);
    background: linear-gradient(180deg, rgba(119, 48, 21, 1) 0%, rgba(95, 9, 121, 1) 27%, rgba(23, 86, 110, 1) 99%);
    color: $color-white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;

    @media (min-width: $nav-breakpoint) {
        display: none;
    }

    .mobile-brand {
        align-content: center;
    }

    .mobile-header-flex {
        display: flex;
        flex-flow: wrap;
        justify-content: space-around;
        align-items: center;
        padding: 20px 0;
    }
}
