/* Colours */
$font-color: #383838;
$link-color: #1F596B;
$color-white: #FFFFFF;
$color-black: #000000;

$purple-120: #240c3d;
$purple-100: #5f0979;
$purple-80: #9240ad;
$purple-60: #b780cf;
$pink-120: #ad0a82;
$blue-120: #041739;
$blue-100: #200d75;
$blue-80: #504f99;
$blue-60: #439cbf;
$teal-120: #07445c;
$teal-100: #17556e;


