/* Colours */
* {
  font-family: "Molengo", sans-serif;
}

body {
  background-color: #FFFFFF;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

section {
  margin: auto;
  padding: 10px 5px 0;
  width: 60%;
}

section:last-child {
  padding-bottom: 20px;
}

footer p {
  color: white;
}

h1, h2, h3, h4, h5, h6, p {
  color: #383838;
  font-family: "Molengo", sans-serif;
}

a {
  color: #1F596B;
  font-weight: 500;
  text-decoration: underline;
}

h1 {
  font-size: 34px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
  font-weight: 700;
}

p, ul, li {
  font-size: 18px;
}

.heading h1 {
  font-size: 44px;
}

.heading h2 {
  font-size: 38px;
}

.heading {
  border-bottom: solid rgb(135, 97, 170) 2px;
}

#heading-section {
  margin-top: 130px;
}

.header-wrapper,
.footer-wrapper {
  margin: auto;
  width: 100%;
}

.header-wrapper {
  margin-top: 15px;
  position: sticky;
  height: 30px;
}

header {
  background: rgb(206, 213, 244);
  background: linear-gradient(36deg, rgb(206, 213, 244) 0%, rgb(95, 9, 121) 79%, rgb(23, 86, 110) 100%);
  width: 100%;
  /* animation magic */
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  z-index: 9999;
  position: fixed;
  top: 0;
}
header-items {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 60%;
}
header .role-nav a {
  text-decoration: none;
  position: relative;
}
header .role-nav a::after {
  content: "";
  background-color: #FFFFFF;
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 1px;
  width: 100%;
  transition: all 0.3s ease;
}
header .role-nav a:hover::after, header .role-nav a:focus::after {
  width: 0;
}

.brand {
  width: 20%;
  float: left;
}

.logo-1 {
  font-size: 0;
  height: 100px;
  margin: 10px 0;
  position: relative;
  top: 5px;
  transition: all 0.3s ease-in;
  width: 180px;
}

.logo-1-background {
  background-image: url("../images/moth-logo-large.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  transition: all 0.3s ease-in;
}

.logo-2 {
  background-image: url("../images/moth-logo-horizontal-dark.svg");
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 0;
  top: 10px;
  height: 100%;
  position: absolute;
  transform: translateZ(0) scale(1, 1) !important;
  -webkit-transform: translate3d(0, 0, 0);
  width: 200px;
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease 0.3s;
  -moz-animation: fadeIn ease 0.3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Mobile logo */
.logo-3 {
  background-image: url("../images/moth-logo-horizontal-light.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  font-size: 0;
  padding-bottom: 20px;
  height: 30px;
  width: 150px;
}

.logo {
  min-height: 26px;
  line-height: 0;
}

header {
  display: none;
}
@media (min-width: 768px) {
  header {
    display: block;
  }
}

.mobile-header {
  max-height: 100vh;
  overflow-y: scroll;
  background: rgb(119, 48, 21);
  background: linear-gradient(180deg, rgb(119, 48, 21) 0%, rgb(95, 9, 121) 27%, rgb(23, 86, 110) 99%);
  color: #FFFFFF;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
@media (min-width: 768px) {
  .mobile-header {
    display: none;
  }
}
.mobile-header .mobile-brand {
  align-content: center;
}
.mobile-header .mobile-header-flex {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
}

footer {
  height: 75px;
  width: 100%;
  margin-top: auto;
  padding-top: 20px;
  animation: pulse 30s infinite;
}
footer a {
  color: #FFFFFF;
  text-decoration: none;
  position: relative;
}
footer a::after {
  content: "";
  background-color: #FFFFFF;
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 1px;
  width: 100%;
  transition: all 0.3s ease;
}
footer a:hover, footer a:focus {
  color: #FFFFFF;
}
footer a:hover::after, footer a:focus::after {
  width: 0;
}

@keyframes pulse {
  0% {
    background-color: #240c3d;
  }
  10% {
    background-color: #5f0979;
  }
  20% {
    background-color: #ad0a82;
  }
  30% {
    background-color: #9240ad;
  }
  40% {
    background-color: #b780cf;
  }
  50% {
    background-color: #504f99;
  }
  60% {
    background-color: #439cbf;
  }
  70% {
    background-color: #17556e;
  }
  80% {
    background-color: #07445c;
  }
  90% {
    background-color: #041739;
  }
  100% {
    background-color: #200d75;
  }
}
.footer-items {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 60%;
}

.full-width-card {
  width: 100%;
}

.project-card {
  height: 100%;
}
.project-card img {
  border-top: 10px solid #000000;
  border-bottom: 10px solid #000000;
}
.project-card .card-divider {
  background-color: #240c3d;
  color: #FFFFFF;
  min-height: 80px;
}
.project-card .card-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Navigation section */
.role-nav-container {
  justify-content: flex-end;
}

.role-nav {
  display: flex;
  float: right;
  line-height: 1.2em;
  margin: 15px 0 0;
  position: relative;
}

.role-nav ol {
  list-style-type: none;
  padding-left: 0;
}

.role-nav ul li:last-child {
  border-right: none;
}

.role-nav-item a {
  color: #FFFFFF;
  font-size: 1.2em;
  font-weight: 500;
}

li.role-nav-item {
  display: inline-block;
  border-right: solid 2px;
  color: #FFFFFF;
  font-size: 90%;
  font-weight: 900;
  padding: 0 6px 0 2px;
}

.mobile-navigation-items ul {
  list-style-type: none;
  margin: 0;
  display: flex;
}
.mobile-navigation-items ul li:not(:last-child) {
  margin-right: 10px;
  padding-right: 10px;
  position: relative;
}
.mobile-navigation-items ul li:not(:last-child)::after {
  content: "";
  background-color: #FFFFFF;
  position: absolute;
  top: 2px;
  right: 0;
  height: calc(100% - 4px);
  width: 1px;
}
.mobile-navigation-items ul li:hover a, .mobile-navigation-items ul li:focus a {
  text-decoration: underline;
}
.mobile-navigation-items ul li a {
  color: #FFFFFF;
  text-decoration: none;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}