/* Navigation section */
.role-nav-container {
    justify-content: flex-end;
}

.role-nav {
    display: flex;
    float: right;
    line-height: 1.2em;
    margin: 15px 0 0;
    position: relative;
}

.role-nav ol {
    list-style-type: none;
    padding-left: 0;
}

.role-nav ul li:last-child {
    border-right: none;
}

.role-nav-item a {
    color: $color-white;
    font-size: 1.2em;
    font-weight: 500;
}

li.role-nav-item {
    display: inline-block;
    border-right: solid 2px;
    color: $color-white;
    font-size: 90%;
    font-weight: 900;
    padding: 0 6px 0 2px;
}

.mobile-navigation {
    &-items {
        ul {
            list-style-type: none;
            margin: 0;
            display: flex;

            li {
                &:not(:last-child) {
                    margin-right: 10px;
                    padding-right: 10px;
                    position: relative;

                    &::after {
                        content: '';
                        background-color: $color-white;
                        position: absolute;
                        top: 2px;
                        right: 0;
                        height: calc(100% - 4px);
                        width: 1px;
                    }
                }

                &:hover,
                &:focus {
                    a {
                        text-decoration: underline;
                    }
                }

                a {
                    color: $color-white;
                    text-decoration: none;
                }
            }
        }
    }
}